<template>
  <CategoryForm backPath="/categories" />
</template>

<script>
import CategoryForm from '../../components/categories/CategoryForm';

export default {
  components: { CategoryForm },
};
</script>
